import { AspectRatio, Box, Center, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import PlaceBetImage from '../../assets/place_bet.png';
import WatchOutImage from '../../assets/watch_out.png';
import CashoutImage from '../../assets/cashout.png';

const INFO_ITEMS = [
  {
    title: 'Place your bet',
    description: <p>Place a bet, or even two at once, and wait until the round begins.</p>,
    image: PlaceBetImage,
  },
  {
    title: 'Watch out',
    description: (
      <p>
        Watch out for the lucky plane. <br />
        Your winnings are equal to your bet times the multiplier! Let&apos;s hope your plane is
        lucky.
      </p>
    ),
    image: WatchOutImage,
  },
  {
    title: 'Cashout',
    description: (
      <p>
        Cash out before the JetX blows up!
        <br />
        The money is yours, time for another round.
      </p>
    ),
    image: CashoutImage,
  },
];

export default function Home() {
  return (
    <VStack w='100%' spacing='24' py='12'>
      {/* <AspectRatio ratio={16 / 9} w='100%'>
        <iframe
          title='Game'
          src='https://eu-staging.ssgportal.com/GameLauncher/Loader.aspx?GameCategory=JetX&GameName=JetX&Token=DEMO&PortalName=demo'
        />
      </AspectRatio> */}

      <Stack direction={{ base: 'column', lg: 'row' }} w='100%' spacing='6' alignItems='stretch'>
        {INFO_ITEMS.map(({ title, description, image }, idx) => (
          <VStack key={idx} w='100%' bg='whiteAlpha.200' p='5' spacing='8' borderRadius='md'>
            <HStack w='100%' alignItems='center' fontWeight='bold'>
              <Center bg='brand.500' px='2' h='72px' color='black'>
                <Text fontSize='4xl'>{idx + 1}</Text>
              </Center>
              <Text fontSize='4xl' color='brand.500' lineHeight='1' textTransform='uppercase'>
                {title}
              </Text>
            </HStack>

            <Box as='span' lineHeight='1.2' fontWeight='semibold'>
              {description}
            </Box>

            <Image src={image} w='100%' objectFit='contain' maxH='40' />
          </VStack>
        ))}
      </Stack>
    </VStack>
  );
}
