import axios from 'axios';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface IAuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  authenticate: (address: string, signature: string) => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  authenticate: async () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }: { children: JSX.Element }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const myself = useCallback(async () => {
    try {
      const token = localStorage.getItem('crashgame-token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;

        await axios.get('/api/myself');
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const authenticate = useCallback(
    async (address, signature) => {
      console.log('Authenticating');
      setIsLoading(true);
      if (address && signature) {
        try {
          const { data } = await axios.post('/api/users/authenticate', {
            address,
            signature,
          });

          if (data.Authorization) {
            localStorage.setItem('crashgame-token', data.Authorization);
            await myself();
            // setUser
          }
        } catch (err) {
          console.error(err);
        }
      }
      setIsLoading(false);
    },
    [myself]
  );

  useEffect(() => {
    myself();
  }, [myself]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        authenticate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
