import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useConnect } from 'wagmi';
import MetamaskIcon from '../../assets/web3/metamask.png';
import WalletConnectIcon from '../../assets/web3/walletConnect.svg';

function ConnectorIcon({ name }: { name: string }) {
  switch (name) {
    case 'MetaMask':
      return <Image src={MetamaskIcon} alt='Metamask' h='6' objectFit='contain' />;
    case 'WalletConnect':
      return <Image src={WalletConnectIcon} alt='WalletConnect' h='6' objectFit='contain' />;
    default:
      return null;
  }
}

export default function ConnectModal({
  children,
  ...props
}: {
  children: JSX.Element;
  [key: string]: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ data }, connect] = useConnect();

  return (
    <>
      <Box cursor='pointer' onClick={onOpen} {...props}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Connect Wallet
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <VStack w='100%'>
              {data.connectors.map(connector => (
                <Button key={connector.name} w='100%' onClick={() => connect(connector)}>
                  <HStack>
                    <ConnectorIcon name={connector.name} />
                    <Text>{connector.name}</Text>
                  </HStack>
                </Button>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
