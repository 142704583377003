import { Image, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import JetXMobile from '../../assets/jetx_mobile.png';
import Plus18 from '../../assets/18plus.png';
import Mastercard from '../../assets/mastercard.png';
import Neteller from '../../assets/neteller.png';
import Visa from '../../assets/visa.png';
import VisaVerified from '../../assets/visa_verified.png';
import Bitcoin from '../../assets/bitcoin.png';

const MENUS = [
  {
    title: 'About us',
    items: [
      {
        title: 'Company details',
        href: '',
      },
      {
        title: 'Terms & Conditions',
        href: '',
      },
      {
        title: 'Bonus Terms',
        href: '',
      },
      {
        title: 'Responsible Gaming',
        href: '',
      },
      {
        title: 'Contact us',
        href: '',
      },
    ],
  },
  {
    title: 'Support',
    items: [
      {
        title: 'Withdraw',
        href: '',
      },
      {
        title: 'Deposit',
        href: '',
      },
      {
        title: 'Affiliate',
        href: '',
      },
    ],
  },
  {
    title: 'Bonus',
    items: [
      {
        title: 'JetX | 10 Free Flights',
        href: '',
      },
      {
        title: 'JetX | 100% Up to 200%',
        href: '',
      },
    ],
  },
];

export default function LayoutFooter({ ...props }) {
  return (
    <VStack
      w='100%'
      alignItems='start'
      bg='whiteAlpha.200'
      borderRadius='md'
      py='5'
      px='10'
      spacing='6'
      {...props}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3 }}
          gap={{ base: '12', md: '24' }}
          alignItems='flex-start'
        >
          {MENUS.map(({ title, items }) => (
            <VStack key={title} alignItems='flex-start' spacing='1'>
              <Text fontWeight='semibold' color='brand.500' pb='2'>
                {title}
              </Text>
              {items.map(({ title, href }) => (
                <Text key={title} as='a' href={href} fontSize='sm' color='gray.200'>
                  {title}
                </Text>
              ))}
            </VStack>
          ))}
        </SimpleGrid>
        <Image src={JetXMobile} pt='6' />
      </Stack>
      <SimpleGrid
        templateColumns={{ base: 'repeat(2, auto)', sm: 'repeat(3, auto)', md: 'repeat(6, auto)' }}
        alignItems='center'
        gap='6'
        alignSelf={{ base: 'flex-start', lg: 'flex-end' }}
      >
        <Image src={Plus18} />
        <Image src={Visa} />
        <Image src={VisaVerified} />
        <Image src={Mastercard} />
        <Image src={Neteller} />
        <Image src={Bitcoin} />
      </SimpleGrid>
    </VStack>
  );
}
