import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query/react'
import user from './user/reducer';
import dashboard from './dashboard/reducer';
import events from './events/reducer';
import { load, save } from 'redux-localstorage-simple';
import { userApi } from "./user/slice";

const PERSISTED_KEYS: string[] = ['user'];

const store = configureStore({
  reducer: {
    user,
    dashboard,
    events,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true })
      .concat(userApi.middleware)
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS })
});


setupListeners(store.dispatch);

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;