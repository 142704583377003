import {
  Button,
  Divider,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ChainId, ChainIdToImageURL, defaultChains } from 'constants/web3';
import { useAuth } from 'providers/AuthProvider';
import { useCallback } from 'react';
import { FaChevronDown, FaUser } from 'react-icons/fa';
import { Chain, useAccount, useNetwork, useSignMessage } from 'wagmi';
import ConnectModal from './ConnectModal';

export default function Account() {
  const { isAuthenticated, isLoading, authenticate } = useAuth();
  const [{ data: account }, disconnect] = useAccount();
  const [{ data: network }, switchNetwork] = useNetwork();
  const [, signMessage] = useSignMessage({
    message: 'gm wagmi frens',
  });

  const handleAuth = useCallback(async () => {
    console.log('Connect');
    const { data } = await signMessage({
      message: `I want to login into https://crashgame.com at ${Math.round(
        Date.now() / 1000 / 60 / 60
      )}`,
    });
    console.log(data);
    await authenticate(account?.address as string, data as string);
  }, [account?.address, authenticate, signMessage]);

  if (!account) {
    return (
      <ConnectModal ml='auto'>
        <Button variant='primary'>Connect</Button>
      </ConnectModal>
    );
  }

  if (!isAuthenticated) {
    return (
      <Button variant='primary' onClick={handleAuth} isLoading={isLoading}>
        Login
      </Button>
    );
  }

  const shortAddress = account?.address?.substr(0, 6) + '...' + account?.address?.substr(-2);

  return (
    <Menu autoSelect={false}>
      <MenuButton>
        <HStack>
          <Icon as={FaUser} fontSize='3xl' />
          <VStack alignItems='flex-start' spacing='0' lineHeight='1.3'>
            <Text fontSize='xs' color='gray.300'>
              {account?.ens || shortAddress}
            </Text>
            <Text>@0xSmok</Text>
          </VStack>
          <Icon as={FaChevronDown} color='gray.300' />
        </HStack>
      </MenuButton>
      <MenuList pb='0'>
        <SimpleGrid columns={4} px='2'>
          {Object.values(defaultChains).map(chain => (
            <NetworkItem
              key={chain.id}
              chain={chain}
              switchNetwork={switchNetwork ? switchNetwork : () => {}}
              isCurrent={network.chain?.id === chain.id}
            />
          ))}
        </SimpleGrid>
        <Divider mt='2' />
        <MenuItem>
          <Button variant='none' size='sm' w='100%' onClick={disconnect}>
            Disconnect
          </Button>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function NetworkItem({
  chain,
  switchNetwork,
  isCurrent,
}: {
  chain: Chain;
  switchNetwork: (chainId: ChainId) => void;
  isCurrent: boolean;
}) {
  return (
    <Tooltip key={chain.id} label={chain.name}>
      <MenuItem
        chain={chain}
        onClick={() => switchNetwork(chain.id as ChainId)}
        w='12'
        h='12'
        p='2'
        borderRadius='sm'
        bg={isCurrent ? 'whiteAlpha.200' : 'transparent'}
      >
        <Image src={ChainIdToImageURL[chain.id as ChainId]} w='100%' h='100%' objectFit='cover' />
      </MenuItem>
    </Tooltip>
  );
}
