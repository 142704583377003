import {
  Button,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { ChainId, ChainIdToName, Token, TokenList } from 'constants/web3';
import { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useAccount, useBalance, useNetwork } from 'wagmi';

export default function TokenSelect() {
  const [{ data: account }] = useAccount();
  const [{ data: network }] = useNetwork();

  const [token, setToken] = useState<Token | undefined>();

  useEffect(() => {
    setToken(TokenList[ChainIdToName[network.chain?.id as ChainId]]?.[0]);
  }, [network.chain?.id]);

  if (!account || !token) {
    return null;
  }

  return (
    <Menu autoSelect={false}>
      <MenuButton
        py='2'
        px='5'
        borderRadius='md'
        _hover={{
          bg: 'gray.700',
        }}
      >
        <HStack spacing='4'>
          <HStack>
            <TokenItem account={account?.address as string} token={token as Token} h='9' />
            <Icon as={FaChevronDown} color='gray.300' />
          </HStack>
          <Button variant='primary'>Deposit</Button>
        </HStack>
      </MenuButton>
      <MenuList>
        {TokenList[ChainIdToName[network.chain?.id as ChainId]]?.map(token => (
          <MenuItem key={token.address} token={token} onClick={() => setToken(token)}>
            <TokenItem account={account?.address as string} token={token} color='gray.300' h='6' />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

function TokenItem({
  account,
  token,
  ...props
}: {
  account: string;
  token: Token;
  [x: string]: any;
}) {
  const [{ data }] = useBalance({
    addressOrName: account,
    token: token.address,
  });

  return (
    <HStack {...props}>
      <Image src={token?.logoURI} h='100%' borderRadius='full' />
      <HStack spacing='1'>
        <Text>{new BigNumber(data?.formatted as BigNumber.Value)?.toFixed(2)}</Text>
        <Text>{token?.symbol}</Text>
      </HStack>
    </HStack>
  );
}
