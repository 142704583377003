import axios from 'axios';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.crashgame.com';
} else {
  axios.defaults.baseURL = 'https://crashgameapi.herokuapp.com/';
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('crashgame-token');
    //   window.location = '/' as unknown as Location;
      console.error(error);
      return error;
    }
  }
);
