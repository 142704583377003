import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import components from './components';
import config from './config';
import fonts from './fonts';
import styles from './styles';

const themeConfig = {
  colors,
  components,
  config,
  fonts,
  styles
}

export default extendTheme(themeConfig);