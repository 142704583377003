import { Box, Image, VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import LayoutHeader from './LayoutHeader';
import BackgroundTopImage from '../../assets/background_top.png';
import BackgroundBottomImage from '../../assets/background_bottom.png';
import LayoutFooter from './LayoutFooter';

export default function Layout() {
  const headerHeight = '70px';

  return (
    <Box w='100%' h='max-content' position='relative'>
      <VStack w='100%' h='100%' spacing='0' zIndex='1'>
        <Image
          flex={1}
          src={BackgroundTopImage}
          w='100%'
          objectFit='cover'
          opacity='0.5'
          zIndex='1'
        />
        <Image
          flex={1}
          src={BackgroundBottomImage}
          w='100%'
          objectFit='cover'
          opacity='0.9'
          boxShadow='0 -10px 10px 20px rgba(4, 4, 38, 0.95)'
          zIndex='2'
        />
      </VStack>
      <Box w='100%' py='6' h='100%' position='absolute' top='0' left='0' zIndex='2' px='6'>
        <LayoutHeader height={headerHeight} />
        <VStack w='100%' h={`calc(100% - ${headerHeight})`} overflowY='auto'>
          <VStack h='100%' w='100%' maxW='1200px' justifyContent='space-between'>
            <Outlet />
            <LayoutFooter />
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
}
