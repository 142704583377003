import { createReducer } from "@reduxjs/toolkit";
import { updateDashboardDate } from "./actions";

export interface DashboardState {
  dashboardDate: string;
}

export const InitialState: DashboardState = {
  dashboardDate: new Date().toString(),
}


export default createReducer(InitialState, (builder) => {
  builder.addCase(updateDashboardDate, (state, action) => {
    state.dashboardDate = action.payload.dashboardDate
  })
})
