const components = {
  Tooltip: {
    baseStyle: {
      bg: 'gray.700',
      color: 'gray.50',
      borderRadius: 'sm',
      py: '1',
      px: '2',
    },
  },
  Button: {
    baseStyle: {
      borderRadius: 'md',
      px: '6',
    },
    variants: {
      primary: {
        bg: 'brand.500',
        color: 'black',

        _hover: {
          bg: 'brand.700',
        },
        _active: {
          bg: 'brand.800',
        },
      },

      secondary: {
        bg: 'transparent',
        border: '1px',
        borderColor: 'brand.500',
        color: 'brand.500',

        _hover: {
          bg: 'brandAlpha.300',
          color: 'white',
        },
        _active: {
          bg: 'brandAlpha.500',
          color: 'white',
        },
      },
    },
  },
  Modal: {
    baseStyle: {
      dialog: {
        py: '1',
        borderRadius: 'md',
        bg: 'gray.900',
      },
      header: {
        fontSize: 'md',
        borderBottom: '1px solid',
        borderColor: 'gray.700',
        py: '3',
      },
      body: {
        py: '5',
      },
      footer: {
        py: '3',
        borderTop: '1px solid',
        borderColor: 'gray.700',
      },
    },
  },
  Menu: {
    baseStyle: {
      list: {
        bg: 'gray.800',
        border: '0px',
        borderColor: 'gray.400',
      },
    },
  },
};

export default components;
