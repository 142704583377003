const colors = {
  brand: {
    50: 'hsl(50, 91%, 85%)',
    100: 'hsl(50, 91%, 80%)',
    200: 'hsl(50, 91%, 75%)',
    300: 'hsl(50, 91%, 70%)',
    400: 'hsl(50, 91%, 65%)',
    500: 'hsl(50, 91%, 60%)',
    600: 'hsl(50, 91%, 55%)',
    700: 'hsl(50, 100%, 45%)',
    800: 'hsl(50, 100%, 40%)',
    900: 'hsl(50, 100%, 35%)',
  },
  brandAlpha: {
    50: 'hsla(50, 91%, 40%, 0.1)',
    100: 'hsla(50, 91%, 40%, 0.2)',
    200: 'hsla(50, 91%, 40%, 0.3)',
    300: 'hsla(50, 91%, 40%, 0.4)',
    400: 'hsla(50, 91%, 40%, 0.5)',
    500: 'hsla(50, 91%, 60%, 0.5)',
    600: 'hsla(50, 91%, 60%, 0.6)',
    700: 'hsla(50, 100%, 45%, 0.6)',
    800: 'hsla(50, 100%, 45%, 0.7)',
    900: 'hsla(50, 100%, 45%, 0.8)',
  },
  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#c0c0c0',
    400: '#636366',
    500: '#48484a',
    600: '#3a3a3c',
    700: '#2c2c2e',
    800: '#1c1c1e',
    900: '#0e0e0f',
  },
};

export default colors;
