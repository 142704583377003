import { InjectedConnector, chain, Chain } from 'wagmi';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import MainnetImage from '../assets/web3/mainnet.png';
import BSCImage from '../assets/web3/bsc.svg';
import PolygonImage from '../assets/web3/polygon.svg';

export type ChainId = 1 | 56 | 137 | 80001;
export type ChainName = 'mainnet' | 'polygonMainnet' | 'polygonTestnetMumbai' | 'bscMainnet';

export const ChainIdToName: Record<ChainId, ChainName> = {
  1: 'mainnet',
  137: 'polygonMainnet',
  80001: 'polygonTestnetMumbai',
  56: 'bscMainnet',
};

export const ChainIdToImageURL: Record<ChainId, string> = {
  1: MainnetImage,
  137: PolygonImage,
  80001: PolygonImage,
  56: BSCImage,
};

export const defaultChains = {
  mainnet: chain.mainnet,
  polygonMainnet: chain.polygonMainnet,
  polygonTestnetMumbai: chain.polygonTestnetMumbai,
  bscMainnet: {
    id: 56,
    name: 'Binance Smart Chain',
    nativeCurrency: {
      decimals: 18,
      name: 'Binance Coin',
      symbol: 'BNB',
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorers: [{ name: 'BSC Scan', url: 'https://bscscan.com' }],
    testnet: false,
  } as Chain,
};

export const connectors = [
  new InjectedConnector({ chains: Object.values(defaultChains) }),
  new WalletConnectConnector({
    chains: Object.values(defaultChains),
    options: {
      infuraId: '9aa3d95b3bc440fa88ea12eaa4456161',
      qrcode: true,
    },
  }),
];

export interface Token {
  chainId: ChainId;
  address: string;
  decimals: number;
  symbol: string;
  name: string;
  logoURI: string;
  coingeckoId: string;
}

export const TokenList: Record<ChainName, Token[]> = {
  mainnet: [
    {
      chainId: 1,
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
      coingeckoId: 'tether',
    },
    {
      chainId: 1,
      name: 'Ether',
      symbol: 'ETH',
      address: '',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png',
      coingeckoId: 'ethereum',
    },
  ],
  polygonMainnet: [
    {
      chainId: 137,
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
      coingeckoId: 'tether',
    },
  ],
  polygonTestnetMumbai: [
    {
      chainId: 80001,
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xdf45969D5F02A005f13F612D447CE412a0831709',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
      coingeckoId: 'tether',
    },
  ],
  bscMainnet: [
    {
      chainId: 56,
      name: 'Tether',
      symbol: 'USDT',
      address: '0x55d398326f99059fF775485246999027B3197955',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
      coingeckoId: 'tether',
    },
  ],
};
