import { createReducer } from "@reduxjs/toolkit";
import { DEFAULT_LOCALE, SupportedLocale } from "constants/locales";
import { logout, updateUserLocale } from "./actions";

export interface UserState {
  isAuthenticated: boolean;
  username: string;
  userLocale: SupportedLocale;
}

export const InitialState: UserState = {
  isAuthenticated: false,
  username: '',
  userLocale: DEFAULT_LOCALE
}


export default createReducer(InitialState, (builder) => {
  builder.addCase(logout, state => {
    state.username = '';
    state.isAuthenticated = false;
  })
  builder.addCase(updateUserLocale, (state, action) => {
    state.userLocale = action.payload.userLocale;
  })
})