import { HStack, Image, VStack } from '@chakra-ui/react';
import Account from 'components/Account';
import TokenSelect from 'components/TokenSelect';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import LogoAppendix from '../../assets/logo_appendix.png';

export default function LayoutHeader({ height }: { height: string }) {
  return (
    <HStack
      w='100%'
      height={height}
      maxW='1200px'
      justifyContent='space-between'
      mx='auto'
      spacing='16'
    >
      <VStack as={Link} spacing='3' to='/' cursor='pointer' position='relative'>
        <Image src={Logo} zIndex='2' maxW='200px' />
        <Image
          src={LogoAppendix}
          zIndex='1'
          maxW='150px'
          position='absolute'
          left='0'
          right='0'
          mx='auto'
        />
      </VStack>
      <HStack
        w='100%'
        py='2'
        justifyContent='space-between'
        spacing='2'
        bg='gray.900'
        px='3'
        borderRadius='md'
      >
        <TokenSelect />

        <Account />
      </HStack>
    </HStack>
  );
}
