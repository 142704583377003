import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from 'i18n';
import { Provider as WAGMIProvider } from 'wagmi';
import theme from 'theme';
import Routes from './Routes';
import { connectors, defaultChains } from 'constants/web3';
import { providers } from 'ethers';
import { Buffer } from 'buffer';
import { AuthProvider } from 'providers';
import '../config/axios';

declare global {
  interface Window {
    Buffer: any;
  }
}

// polyfill Buffer for client (missing for WalletConnect)
if (!window.Buffer) {
  window.Buffer = Buffer;
}

const provider = ({ chainId }: any) => {
  if (chainId === defaultChains.bscMainnet.id) {
    return new providers.JsonRpcProvider('https://bsc-dataseed.binance.org/', chainId);
  } else {
    return new providers.InfuraProvider(chainId, '9aa3d95b3bc440fa88ea12eaa4456161');
  }
};

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <WAGMIProvider autoConnect connectors={connectors} provider={provider}>
        <AuthProvider>
          <BrowserRouter>
            <LanguageProvider>
              <Routes />
            </LanguageProvider>
          </BrowserRouter>
        </AuthProvider>
      </WAGMIProvider>
    </ChakraProvider>
  );
};
