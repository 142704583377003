import { Text } from '@chakra-ui/react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import Layout from 'components/Layout';
import Home from './Home';

export default function Routes() {
  return (
    <RouterRoutes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
      </Route>
      <Route path='*' element={<Text>Not Found</Text>} />
    </RouterRoutes>
  );
}
