import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

interface Post {
  id: string;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://jsonplaceholder.typicode.com/' }),
  endpoints: (builder) => ({
    getPosts: builder.query<Post, void>({
      query: () => '/posts'
    }),
    getPostsById: builder.query<Post, string>({
      query: postId => `/posts/${postId}`
    })
  })
});


export const { useGetPostsQuery, useGetPostsByIdQuery } = userApi;